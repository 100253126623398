<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableNew'
import moment from 'moment'
import { hotelMixin } from '@/mixins/orderProcessing/hotelMixin'
import { eatDrinkMixin } from '@/mixins/orderProcessing/eatDrinkMixin'
import { farmingExperienceMixin } from '@/mixins/orderProcessing/farmingExperienceMixin'
import { scenicAreasTicketsMixin } from '@/mixins/orderProcessing/scenicAreasTickets'
import { localProductsMixin } from '@/mixins/orderProcessing/localProductsMixin'

export default {
  mixins: [hotelMixin, eatDrinkMixin, farmingExperienceMixin,scenicAreasTicketsMixin,localProductsMixin],
  methods: {
    getTimePickerKeys() {
      const { dateTypeQuery } = this.$route.query
      switch (dateTypeQuery) {
        case 0:
          return ['dateGe', 'dateLe']
        case 1:
          return ['dateMonthGe', 'dateMonthLe']
        case 2:
          return ['dateYearStr', 'dateYearStr']
      }
    },
    getHeader() {
      const { timeStr } = this.$route.query
      return [
        {
          type: 'select',
          name: '订单状态',
          key: 'status',
          typeData: [
            {
              name: '全部',
              value: '',
            },
            {
              name: '待使用',
              value: 1,
            },
            {
              name: '已完成',
              value: 5,
            },
          ],
        },
        {
          type: 'rangePicker',
          name: '时间',
          key: 'timeStr',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '出售商品',
          dataIndex: 'productName',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                {records.productPic ? <img src={records.productPic.split(',')[0]} /> : ''}
                <div>{text}</div>
              </div>
            )
          },
        },
        {
          title: '景点名称',
          dataIndex: 'businessName',
        },
        {
          title: '数量',
          dataIndex: 'productQuantity',
        },
        {
          title: '实付金额',
          dataIndex: 'payAmount',
        },
        {
          title: '购买人',
          dataIndex: 'buyName',
        },
        {
          title: '手机号',
          dataIndex: 'buyPhone',
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (data) => {
            const state = ['待支付', '待使用', '待发货', '待签收', '待评价', '已完成', '已关闭'][data]
            return <div>{state}</div>
          },
        },
         {
          dataIndex: 'a1',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                display:true,
                name: '详情',
                onClick: () => {
                  // this.$router.push(`/dataReport/scenicDetail?businessId=${records.businessId}`)
                   // 订单来源：1农家特产 2 农家乐 4农事体验 12 景点门票 13 酒店民宿
                  const fromType = records.fromType
                  switch (fromType) {
                    case '1':
                      this.onSelectRowLocalProducts(records,true)
                      break
                    case '2':
                      this.onRowSelectEatDrink(records.id)
                      break
                    case '4':
                      this.onRowSelectFarmingExperience(records.id)
                      break
                    case '12':
                      this.onRowSelectScenicAreasTickets(records,null,true)
                      break
                    case '13':
                    case '14':
                      this.onRowSelectHotel(records,true)
                      break
                  }
                },
              },
            ]
          },
        },
      ]
    },
    onHeaderCallBack(data) {
      if (data.timeStr) {
        const dateGe = moment(data.timeStr[0]).format('YYYY-MM-DD')
        const dateLe = moment(data.timeStr[1]).format('YYYY-MM-DD')
        return { dateGe, dateLe, dateTypeQuery: '0' }
      } else {
        return {}
      }
    },
  },
  render() {
    const { dateLe, dateGe, dateTypeQuery, userId } = this.$route.query
    return (
      <TemplateHeaderTableNew
        url={`/reportForm/getOrderPage`}
        pageParams={{ userId }}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        onHeaderCallBack={this.onHeaderCallBack}
      />
    )
  },
}
</script>
