import api from '@/command/api'
import orderConfig from '@/orderConfig'
import { getAction, getParams, downloadAction } from '@/command/netTool'


export const localProductsMixin = {
    methods: {
         // 获取选中订单详情
        getDetailDataLocalProducts(id) {
            return new Promise((resolve, reject) => {
            getAction(`/api/product/order/detail?id=${id}`)
                .then((result) => {
                resolve({
                    ...result.data,
                    valCopy:
                    result.data.deliveryList && result.data.deliveryList.length > 0 ? result.data.deliveryList[0] : {},
                })
                })
                .catch((e) => reject && reject(e))
            })
        },
        // 已关闭
        completed(data) {
            orderConfig.completed({
                data,
                params: {
                    data,
                    typeData: {
                        courierData: this.courierData,
                    },
                    type: 'specialty',
                },
                success: ({ data, setHidden }) => {
                    Modal.confirm({
                        title: '提示',
                        content: '此操作将删除该订单, 是否继续？',
                        onOk: () => {
                            api.command.del
                                .call(this, {
                                    url: `/product/order/delete?id=${data.id}`,
                                })
                                .then((res) => {
                                    setHidden()
                                })
                        },
                        onCancel() { },
                    })
                },
            })
        },
        // 确认收货
        received(data) {
            orderConfig.received({
                data,
                params: {
                    data,
                    typeData: {
                        courierData: this.courierData,
                    },
                    type: 'specialty',
                },
                success: ({ data, setHidden }) => {
                    api.order.received.call(this, { url: '/product/order/receiving', params: data }).then(setHidden)
                },
            })
        },
        // 支付
        payment(data) {
            orderConfig.close({
                data,
                params: {
                    data,
                    typeData: {
                        courierData: this.courierData,
                    },
                    type: 'specialty',
                },
                success: ({ setHidden }) => {
                    api.order.close
                        .call(this, {
                            url: '/common/order/close',
                            params: { id: data.id },
                        })
                        .then(setHidden)
                },
            })
        },
        // 待发货
        onDelivery(data) {
            this.getDetailDataLocalProducts(data.id).then((result) => {
                data = result

                orderConfig.delivery({
                    data,
                    params: {
                        data,
                        typeData: {
                            courierData: this.courierData,
                        },
                        type: 'specialty',
                    },
                    success: ({ data, setHidden }) => {
                        if (data.deliveryType == '0') {
                            if (data.deliveryCode) {
                                data.deliveryCompany = this.courierData.find((e) => e.value == data.deliveryCode).name
                            } else {
                                this.$message.warning('请选择发货方式！')
                                return
                            }
                        }
                        if (data.deliverySn) {
                            data.deliverySn = data.deliverySn.replace(/\s*/g, '')
                        }
                        api.order.delivery.call(this, { url: '/product/order/shipping', params: data }).then(setHidden)
                    },
                })
            })
        },
        onSelectRowLocalProducts(records,isDetail) {
            if (isDetail){
                // 详情展示已完成 页面
                this.getDetailDataLocalProducts(records.id).then(this.completed)
            }else {
                // 0->待支付；1->待使用；2->待发货；3->待签收；4->待评价；5->已完成；6->已关闭；
                switch (records.status) {
                    case '6':
                    case '5':
                    case '4':
                        this.getDetailDataLocalProducts(records.id).then(this.completed)
                        break
                    case '3':
                        this.getDetailDataLocalProducts(records.id).then(this.received)
                        break
                    case '2':
                        this.getDetailDataLocalProducts(records.id).then(this.onDelivery)
                        break
                    case '0':
                        this.getDetailDataLocalProducts(records.id).then(this.payment)
                        break
                    default:
                }
            }
            
        },

    }
}
